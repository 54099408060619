import React, { useEffect, useState } from "react";
import CallsHeader from "./CallsHeader";
import CallsTable from "./CallsTable";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { CrmTaskRelatedEndPoints } from "../../../api/Endpoints";
import CallReminder from "./CallReminder";
import CreateCalls from "../Leads/LeadsDetails/LeadOverview/CallsCard/CreateCalls";
import CallDetailsModal from "../Leads/LeadsDetails/LeadOverview/CallsCard/CallDetailsModal";
import TaskActionWarningModal from "../Leads/LeadsDetails/LeadOverview/TaskActionWarning/TaskActionWarningModal";
import AssigneeEmpInTasks from "../CrmMeetings/AssigneeEmpInTasks";
import DeadlineFilter from "../DeadlineFilter";
import AssigneeDetails from "../CrmMeetings/AssigneeDetails";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ImageViewModal from "../Leads/LeadsDetails/LeadOverview/ActivityMandatoryTask/ImageViewModal";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const CrmCalls = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [allCalls, setallCalls] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [editCall, seteditCall] = useState("");
  const [relatedTo, setrelatedTo] = useState("");
  const [activityDetails, setactivityDetails] = useState("");
  const [editTask, seteditTask] = useState("");
  const [statusWarningDetails, setstatusWarningDetails] = useState([]);
  const [taskWarningModal, settaskWarningModal] = useState(false);
  const [mandatoryLoader, setmandatoryLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [imageData, setimageData] = useState([]);
  const [imageModal, setimageModal] = useState(false);
  const [relatedToInfo, setrelatedToInfo] = useState("");
  // Assignee Filter
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [selectedEmpIdForApi, setSelectedEmpIdForApi] = useState([]);
  // Deadline Filter
  const [dateFilter, setDateFilter] = useState(false);
  const [deadlineFilter, setdeadlineFilter] = useState("");
  const [selectedFilterType, setselectedFilterType] = useState("Assignee");
  // Assignee Emp Details
  const [assigneeEmpModal, setassigneeEmpModal] = useState(false);
  const [assigneeDetails, setassigneeDetails] = useState(null);

  const isModuleReadWriteAccessForCalls =
    allModuleAccess?.[PRIVATEROUTES.CRM_CALLS];

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const currentTime = new Date();

  // Get All Calls

  const handleGetAllCalls = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      sortBy: "createdAt",
      filters: {
        businessId: getCurrentWorkspaceId(),
        taskType: "call",
        isDeleted: false,
        searchKey: search,
        relatedTo: relatedTo,
        assignees: !isModuleReadWriteAccessForCalls
          ? [user?._id]
          : selectedEmpIdForApi?.length > 0
          ? selectedEmpIdForApi
          : "",
        deadlineStartDate: deadlineFilter,
        deadlineEndDate: deadlineFilter,
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      setallCalls(res?.result?.data);
      setTotalCounts(res?.result);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllCalls();
      closeAllModals();
    }
  }, [
    page,
    pageLimit,
    search,
    relatedTo,
    selectedFilterType,
    selectedEmpIdForApi,
  ]);

  /********* Reminder Modal Start *********/
  const [reminderModal, setReminderModal] = useState({
    showModal: false,
    reminderDetails: null,
  });
  const closeTimeObject = JSON.parse(localStorage.getItem("close-time"));
  const closetTime = new Date(closeTimeObject?.closestTime);
  useEffect(() => {
    const scheduledTime = new Date(
      closetTime - parseInt(closeTimeObject?.closestTask?.reminder) * 60 * 1000
    );
    const timeDifference = scheduledTime.getTime() - currentTime.getTime();

    if (timeDifference > 0) {
      if (!reminderModal.showModal) {
        const timeoutId = setTimeout(() => {
          setReminderModal({
            showModal: true,
            reminderDetails: closeTimeObject?.closestTask,
          });
        }, timeDifference);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [currentTime, closetTime]);

  /********* Reminder Modal End *********/

  const handleRefresh = () => {
    setSelectedEmpIdForApi(selectedEmpId);
    setassigneeModal(false);
  };

  const handleResetDateFilter = () => {
    setDateFilter(false);
    setselectedFilterType("Deadline");
  };

  const resetSelectedEmployee = (id) => {
    const updatedEmpId = selectedEmpId.filter((empId) => empId !== id);
    const updatedSelectedEmployees = selectedEmployees.filter(
      (emp) => emp._id !== id
    );

    setselectedEmpId(updatedEmpId);
    setselectedEmployees(updatedSelectedEmployees);
    setSelectedEmpIdForApi(updatedEmpId);
  };

  const handleGetMandatoryTask = async (id, statusID, activity, statusName) => {
    startApiCall(null, setmandatoryLoader);
    const data = {
      activityId: id,
      activityStatusId: statusID,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.checkMandatoryTask,
      data
    );
    if (res?.success) {
      const taskToBeDone = findMandatoryItems(res?.result);
      if (taskToBeDone?.some((task) => Object.values(task)[0])) {
        seteditTask({
          task: taskToBeDone,
          statusID,
          activityId: id,
          activity: activity,
          statusName,
        });
        const closeModalDom = document.getElementById("call_details_modal");
        if (closeModalDom) closeModalDom.click();
        setstatusWarningDetails(activity);
        setactivityDetails(activity);
        settaskWarningModal(true);
      } else {
        handleEditActivity(activity, statusID, activity?.taskType);
      }
      setmandatoryLoader(false);
    } else {
      setmandatoryLoader(false);
    }
  };

  const handleEditActivity = async (task, status, taskType) => {
    startApiCall(seterrorMessage, setmandatoryLoader);
    const sendData = {
      leadTaskId: task?._id,
      taskType: taskType,
      title: task?.title,
      dueDate: task?.dueDate,
      priority: task?.priority,
      relatedTo: task?.relatedTo,
      activityStatus: status,
      actionOn: task?.actionOn?.id,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      setmandatoryLoader(false);
      handleGetAllCalls();
      toast.success("Status changed successfully!");
    } else {
      setmandatoryLoader(false);
      seterrorMessage(res.error);
    }
  };

  function findMandatoryItems(data) {
    let tasks = [];

    data?.map((item) => {
      const key = Object.keys(item)[0];
      const mandatory = item[key]?.mandatory;

      if (mandatory) {
        tasks.push({
          [key]: item[key]?.mandatory,
          description: item[key]?.description,
        });
      }
    });

    return tasks;
  }

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CallsHeader
            handleGetAllCalls={handleGetAllCalls}
            setSearch={setSearch}
            search={search}
            setPage={setPage}
            relatedTo={relatedTo}
            setrelatedTo={setrelatedTo}
            setassigneeModal={setassigneeModal}
            setDateFilter={setDateFilter}
            setselectedFilterType={setselectedFilterType}
            isModuleReadWriteAccessForCalls={isModuleReadWriteAccessForCalls}
          />

          {(selectedEmployees?.length > 0 ||
            selectedFilterType === "Deadline") && (
            <div className="card position-sticky py-2 px-2 rounded-0">
              <div
                className="d-flex justify-content-end mb-2"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  overflow: "auto",
                }}
              >
                <div className="d-flex flex-wrap">
                  {selectedEmployees?.length > 0 &&
                    selectedEmployees?.map((emp) => (
                      <span
                        className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                        style={{
                          backgroundColor: "#3862DD",
                          color: "#ffff",
                          zIndex: 9999,
                        }}
                        key={emp?._id}
                      >
                        {emp?.name}
                        <span className="initial-wrap w-30p">
                          <svg
                            onClick={() => {
                              resetSelectedEmployee(emp?._id);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </span>
                      </span>
                    ))}
                  {selectedFilterType === "Deadline" && (
                    <span
                      className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                      style={{ backgroundColor: "#3862DD", color: "#ffff" }}
                    >
                      Deadline
                      <span className="initial-wrap w-30p">
                        <svg
                          onClick={() => {
                            setdeadlineFilter("");
                            setselectedFilterType("");
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="px-3 py-2">
            {!loader && totalCounts?.totalCloseCount !== 0 && (
              <div
                className="progress my-1 rounded-4"
                style={{ height: "30px", background: "#e9ecef" }}
              >
                <div
                  className={`progress-bar progress-bar progress-bar-animated ${
                    totalCounts?.totalCloseCount === totalCounts?.totalCounts
                      ? "bg-success"
                      : ""
                  }`}
                  role="progressbar"
                  style={{
                    width: `${
                      totalCounts?.totalCounts
                        ? (totalCounts.totalCloseCount /
                            totalCounts.totalCounts) *
                          100
                        : 0
                    }%`,
                  }}
                >
                  {totalCounts?.totalCloseCount === totalCounts?.totalCounts ? (
                    <span className="d-flex align-items-center gap-1 justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-check-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                      </svg>
                      Completed
                    </span>
                  ) : (
                    <span className="fw-bold">
                      {Math.round(
                        (totalCounts.totalCloseCount /
                          totalCounts.totalCounts) *
                          100
                      ) + "%"}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar pt-2">
              {!loader && allCalls?.length > 0 && (
                <>
                  <CallsTable
                    handleGetAllCalls={handleGetAllCalls}
                    allCalls={allCalls}
                    page={page}
                    pageLimit={pageLimit}
                    editCall={editCall}
                    seteditCall={seteditCall}
                    setactivityDetails={setactivityDetails}
                    setassigneeEmpModal={setassigneeEmpModal}
                    setassigneeDetails={setassigneeDetails}
                    setrelatedTo={setrelatedToInfo}
                    handleGetMandatoryTask={handleGetMandatoryTask}
                  />
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allCalls?.length}
                    align={"end"}
                  />
                </>
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && allCalls?.length === 0 && (
                <NoData title="No calls added" />
              )}
            </div>
          </div>
        </div>
        <CreateCalls
          handleGetAllTask={handleGetAllCalls}
          isLeadDeal={false}
          handleRefresh={handleGetAllCalls}
        />
        <CallDetailsModal
          loader={loader}
          activityDetails={activityDetails}
          setactivityDetails={setactivityDetails}
          seteditTask={seteditTask}
          handleAllTask={handleGetAllCalls}
          settaskWarningModal={settaskWarningModal}
          setstatusWarningDetails={setstatusWarningDetails}
          editTask={editTask}
          relatedTo={relatedToInfo}
          handleGetMandatoryTask={handleGetMandatoryTask}
          setimageData={setimageData}
          setimageModal={setimageModal}
        />
        {taskWarningModal && (
          <TaskActionWarningModal
            settaskWarningModal={settaskWarningModal}
            editTask={editTask}
            statusWarningDetails={statusWarningDetails}
            handleGetAllTask={handleGetAllCalls}
            handleEditActivity={handleEditActivity}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {assigneeEmpModal && (
          <AssigneeDetails
            setassigneeEmpModal={setassigneeEmpModal}
            assigneeDetails={assigneeDetails}
          />
        )}
        {assigneeModal && (
          <AssigneeEmpInTasks
            setassigneeModal={setassigneeModal}
            selectedEmployees={selectedEmployees}
            setselectedEmployees={setselectedEmployees}
            selectedEmpId={selectedEmpId}
            setselectedEmpId={setselectedEmpId}
            mainloader={loader}
            handleRefresh={handleRefresh}
          />
        )}
        {dateFilter && (
          <DeadlineFilter
            setDateFilter={setDateFilter}
            deadlineFilter={deadlineFilter}
            setdeadlineFilter={setdeadlineFilter}
            handleRefresh={handleResetDateFilter}
          />
        )}
        {imageModal && (
          <ImageViewModal
            imageData={imageData}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {reminderModal?.showModal && (
          <CallReminder
            onHide={() =>
              setReminderModal({ showModal: false, reminderDetails: null })
            }
            reminderDetails={reminderModal.reminderDetails}
            handleGetAllCalls={handleGetAllCalls}
          />
        )}
      </div>
    </div>
  );
};

export default CrmCalls;
