import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  findClosestTime,
  getCallReminderWithInHour,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { CrmTaskRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function CallReminder({ onHide, reminderDetails, handleGetAllCalls }) {
  const currentTime = new Date();
  const user = useSelector((state) => state.user);
  const [Loader, setLoader] = useState(false);
  const snoozeMinutes = [5, 10, 15, 20, 25, 30];

  const handleGetAllTaskWithInHour = async () => {
    startApiCall(null, setLoader);

    const res = await getCallReminderWithInHour();
    localStorage.setItem("close-time", findClosestTime(currentTime, res));
  };

  const handleEditCalls = async (status) => {
    startApiCall(null, setLoader);
    const sendData = {
      leadTaskId: reminderDetails?._id,
      taskType: "call",
      title: reminderDetails?.title,
      scheduledate: reminderDetails?.scheduledate,
      relatedTo: reminderDetails?.relatedTo,
      startTime: reminderDetails?.startTime,
      endTime: reminderDetails?.endTime,
      actionOn: reminderDetails?.actionOn?._id,
      actionBy: user?._id,
      priority: "low",
      status: status,
      callType: reminderDetails?.callType,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetAllCalls();
      toast.success(`Call marked ${status} successfully!`);
    } else {
      setLoader(false);
    }
  };

  const handleExpireTask = async () => {
    startApiCall(null, setLoader);
    const data = {
      leadTaskId: reminderDetails?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.taskisExpired,
      data
    );
    if (res?.success) {
      handleGetAllTaskWithInHour();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const handleSnoozeCall = async (time) => {
    startApiCall(null, setLoader);
    const data = {
      leadTaskId: reminderDetails?._id,
      snoozeDuration: time,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.snoozeLeadTask,
      data
    );
    if (res?.success) {
      onHide();
      handleGetAllTaskWithInHour();
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleReset = (status) => {
    onHide();
    handleExpireTask();
    handleEditCalls(status);
  };
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5 className="ps-2">
              Call on {dateAndTimeFormatter(reminderDetails?.startTime)}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form>
              <div className="d-flex  gap-2 col-12 ">
                <span className="feather-icon px-4 mt-3">
                  <div className="media-head me-1">
                    <Avatar
                      name={reminderDetails?.actionOn?.name}
                      count={1}
                      image=""
                      size="xs"
                      color="primary"
                    />
                  </div>
                </span>
                <div>
                  <div className="d-flex">
                    <div className="p-2 px-2 d-flex flex-column gap-2">
                      <span className="fs-7">Title : </span>
                      <span className="fs-7">Call With : </span>
                      <span className="fs-7">Category : </span>
                      <span className="fs-7">Email : </span>
                      <span className="fs-7">Phone No : </span>
                      <span className="fs-7">Snooze : </span>
                    </div>
                    <div className="p-2 px-3 d-flex flex-column gap-2">
                      <span className="fs-7 text-dark">
                        {reminderDetails?.title}
                      </span>
                      <span className="fs-7 text-dark">
                        {reminderDetails?.actionOn?.name}
                      </span>
                      <span className="fs-7 text-dark">
                        {reminderDetails?.relatedTo}
                      </span>
                      <span className="fs-7 text-dark">
                        {reminderDetails?.actionOn?.email}
                      </span>
                      <span className="fs-7 text-dark">
                        {/* {reminderDetails?.actionOn?.phone && ( */}
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm rounded-9"
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="me-2"
                          >
                            <path
                              d="M2.7404 0.996368C2.55257 0.754871 2.19559 0.732645 1.97925 0.94898L1.20381 1.72442C0.841203 2.08703 0.707902 2.60074 0.866327 3.05135C1.50253 4.86093 2.54411 6.56003 3.99204 8.00796C5.43997 9.45589 7.13907 10.4975 8.94865 11.1337C9.39926 11.2921 9.91297 11.1588 10.2756 10.7962L11.051 10.0207C11.2674 9.80441 11.2451 9.44743 11.0036 9.2596L9.27399 7.91432C9.15062 7.81836 8.98998 7.78446 8.83836 7.82236L7.19656 8.23281C6.75061 8.3443 6.27887 8.21363 5.95384 7.8886L4.1114 6.04616C3.78637 5.72113 3.6557 5.24939 3.76719 4.80344L4.17764 3.16165C4.21555 3.01002 4.18164 2.84938 4.08568 2.72601L2.7404 0.996368ZM1.41358 0.383307C1.97017 -0.173279 2.88862 -0.116098 3.37187 0.505226L4.71715 2.23487C4.96403 2.55228 5.05126 2.96556 4.95374 3.35567L4.54329 4.99747C4.49995 5.1708 4.55074 5.35416 4.67708 5.48049L6.51951 7.32293C6.64585 7.44926 6.8292 7.50005 7.00253 7.45672L8.64433 7.04627C9.03444 6.94874 9.44772 7.03598 9.76513 7.28285L11.4948 8.62813C12.1161 9.11138 12.1733 10.0298 11.6167 10.5864L10.8413 11.3619C10.2865 11.9167 9.45666 12.1603 8.68331 11.8884C6.76316 11.2133 4.96079 10.1081 3.42637 8.57363C1.89195 7.03921 0.786712 5.23684 0.111629 3.31669C-0.160262 2.54334 0.0833475 1.71354 0.638137 1.15875L1.41358 0.383307Z"
                              fill="white"
                            />
                          </svg>
                          {/* + {reminderDetails?.actionOn?.phone} */}+
                          896764231
                        </button>
                        {/* )} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer align-items-center justify-content-between mt-1">
                <div className="d-flex gap-1 ">
                  <div className="selectable-dropdown">
                    <div className="dropdown selectable-dropdown">
                      <button
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        className="btn btn-primary dropdown-toggle btn-sm"
                      >
                        Snooze
                      </button>
                      <div
                        role="menu"
                        className="dropdown-menu"
                        style={{
                          maxHeight: "20vh",
                          overflow: "auto",
                        }}
                      >
                        {snoozeMinutes?.map((time) => (
                          <button
                            className="dropdown-item text-capitalize d-flex align-items-center"
                            type="button"
                            onClick={() => {
                              handleSnoozeCall(time);
                            }}
                          >
                            {time} minutes
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-1">
                  <button
                    type="submit"
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      handleEditCalls("cancel");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-hand-thumbs-down me-1 "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1" />
                    </svg>
                    Mark as Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      handleReset("complete");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-hand-thumbs-up me-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                    </svg>
                    Mark as Completed
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    disabled={snoozeTime}
                    onClick={handleReset}
                  >
                    Okay
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallReminder;
