import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ApiCall from "./api/ApiCall";
import VersionApiCall from "./api/VersionApiCall";
import AllRoutes from "./all-routes";
import AuthRoutes from "./all-routes/Authentication/AuthRoutes";
import {
  ConfigurationRelatedEndPoints,
  authenticateEndpoints,
  versionRelatedEndPoints,
} from "./api/Endpoints";
import PageLoader from "./components/Loaders/PageLoader/PageLoader";
import { useDispatch } from "react-redux";
import { actionCreators } from "./redux-setup";
import {
  checkValidWorkspaceForAdmin,
  findClosestTime,
  getAllMyBusinessesUsingFilter,
  getAllStatusBusinesses,
  getCallReminderWithInHour,
  getCurrentWorkspaceId,
  getMyAllModules,
  getWorkspaceWisePermissionsModules,
  isEmployeeOwnerForPermissionAnotherFunc,
  startApiCall,
} from "./global-functions/globalFunctions";
import { ToastContainer } from "react-toastify";
import MaintainancePage from "./pages/MaintainancePage/MaintainancePage";
import { ACTIVE_VERSION } from "./api/base";
import { setConfiguration } from "./redux-setup/action-creators";
import PlaceOrderForCustomer from "./pages/InventoryManagement/Order/PlaceOrderForCustomer/PlaceOrderForCustomer";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";

function App() {
  const refreshToken = localStorage.getItem("refreshTokenForTaskManager");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const currentWorkspaceId = localStorage.getItem("current-workspace");
  const [isMaintainance, setisMaintainance] = useState(false);
  const [isVersionLoader, setisVersionLoader] = useState(false);
  const [Loader, setLoader] = useState(false);

  // ******** VERSION CHECK FOR DASHBOARD **********
  const handleGetVersionForDashboard = async () => {
    startApiCall(null, setisVersionLoader);
    const data = {
      platform: "dashboard",
      projectName: "bizhood",
    };
    const res = await VersionApiCall(
      "post",
      versionRelatedEndPoints.getDashboardVersion,
      data
    );
    if (res?.success) {
      if (res?.server?.version !== ACTIVE_VERSION) {
        console.log("🚷🚷🚷🚷🚷VERISON DID'T MATHCED🚷🚷🚷🚷🚷");
        setisMaintainance(true);
      } else {
        console.log("✅✅✅✅✅VERISON MATHCED✅✅✅✅✅");
        setisMaintainance(res?.server ? res?.server?.isMaintenance : true);
      }
      setisVersionLoader(false);
    } else {
      setisVersionLoader(false);
    }
  };
  // ******** VERSION API CALLED **********
  useEffect(() => {
    handleGetVersionForDashboard();
  }, []);

  // ******** AUTHENTICATE USER CHECK**********
  const handleAuthenticateUser = async () => {
    console.log("***Logged in Success.Token Verified***");
    const res = await ApiCall("get", authenticateEndpoints.verifyUser, {
      params: { token: refreshToken },
    });
    if (res?.success) {
      dispatch(actionCreators.addAuthenticateUser(res.user));
      let workspaceID = checkValidWorkspaceForAdmin(
        res?.user?.business,
        currentWorkspaceId ? currentWorkspaceId : res.user?.defaultBusinessId,
        res.user.isSuperAdmin
      );
      handleCheckModulePermissions(
        res?.user?.permissions,
        workspaceID,
        res?.user?.isSuperAdmin
      );

      const allWorkspaces = await getAllStatusBusinesses(res?.user?._id);
      let allPendingWorkspaces = await getAllMyBusinessesUsingFilter(
        allWorkspaces?.business,
        "pending"
      );
      let allApprovedWorkspaces = await getAllMyBusinessesUsingFilter(
        allWorkspaces?.business,
        "approved"
      );

      dispatch(actionCreators.myPendingWorkspaces(allPendingWorkspaces));
      dispatch(actionCreators.myApproveWorkspaces(allApprovedWorkspaces));
      setisLoggedIn(true);
      setIsLoaded(true);
    } else {
      setisLoggedIn(false);
      setIsLoaded(true);
    }
  };

  // ******** MODULE PERMISSION CHECKED **********
  const handleCheckModulePermissions = (per, workspaceID, isSuperAdmin) => {
    let isOwner;
    let overallPermissions = [];
    let overallAccess = {};
    if (workspaceID && !isSuperAdmin) {
      isOwner = isEmployeeOwnerForPermissionAnotherFunc(per, workspaceID);
    } else {
      isOwner = true;
    }
    if (isOwner) {
      getMyAllModules()?.map((mdl) => {
        overallAccess[mdl] = true;
        overallPermissions.push(mdl);
      });
    } else {
      let userPermission = getWorkspaceWisePermissionsModules(per, workspaceID);
      userPermission?.map((up) => {
        overallPermissions?.push(up?.moduleName);
        overallAccess[up?.moduleName] =
          up?.permissionType === "read-write" ? true : false;
      });
    }
    dispatch(actionCreators.myModulePermission(overallPermissions));
    dispatch(actionCreators.allModuleAccess(overallAccess));
    // console.log("overallPermissions", overallPermissions);
    // console.log("overallAccess", overallAccess);
  };

  // ******** AUTHENTICE USER API CALLED**********
  useEffect(() => {
    if (refreshToken) {
      handleAuthenticateUser();
    } else {
      setisLoggedIn(false);
      setIsLoaded(true);
    }
  }, []);

  const handleGetConfiguration = async () => {
    const data = {
      page: 1,
      limit: 10,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      ConfigurationRelatedEndPoints.getConfifguration,
      data
    );
    if (res?.success) {
      dispatch(setConfiguration(res?.configuration?.data));
    }
  };

  /********* Reminder Modal *********/
  const currentTime = new Date();

  const handleGetAllTaskWithInHour = async () => {
    startApiCall(null, setLoader);

    const res = await getCallReminderWithInHour();
    localStorage.setItem("close-time", findClosestTime(currentTime, res));
  };

  useEffect(() => {
    handleGetConfiguration();
  }, []);

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllTaskWithInHour();
    }
  }, []);

  return isLoaded ? (
    <>
      <Routes>
        <Route
          path={`/place-order-customer/:workspaceId`}
          element={<PlaceOrderForCustomer />}
        />
        <Route path={`/delete-info-account`} element={<DeleteAccount />} />
      </Routes>
      {!isLoggedIn ? (
        <Routes>
          <Route
            path="/*"
            element={
              isVersionLoader ? (
                <PageLoader />
              ) : isMaintainance ? (
                <MaintainancePage />
              ) : (
                <AuthRoutes />
              )
            }
          />
        </Routes>
      ) : (
        <AllRoutes />
      )}
      <ToastContainer
        position="top-right"
        autoClose={1200}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </>
  ) : (
    <>
      <PageLoader />
    </>
  );
}

export default App;
