import React, { useEffect, useState } from "react";
import ContactsHeader from "./ContactsHeader";
import ContactTable from "./ContactTable";
import CreateContact from "./CreateContact";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { CrmContactRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import AssigneeModal from "../Assignee/AssigneeModal";
import { useLocation, useNavigate } from "react-router-dom";

const Contacts = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [selectedEmpIdForApi, setSelectedEmpIdForApi] = useState([]);
  const [loader, setloader] = useState(false);
  const [allContacts, setallContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [editContact, seteditContact] = useState("");
  const [isBusinessWise, setIsBusinessWise] = useState(false);
  const [businessWise, setBusinessWise] = useState(false);
  const currentWorkSpaceRole = getWorkSpaceRole(user?.permissions, "roleName");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForContact =
    allModuleAccess?.[PRIVATEROUTES.CONTACTS];

  const handleFilterAssignee = () => {
    setassigneeModal(false);
    setSelectedEmpIdForApi(selectedEmpId);
    setBusinessWise(isBusinessWise);
  };
  const resetSelectedEmployee = () => {
    setselectedEmpId([]);
    setSelectedEmpIdForApi([]);
    navigate({ ...location, state: null });
  };

  const handleGetContact = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isRemoved: false,
        createdBy:
          currentWorkSpaceRole === "owner" && isBusinessWise && !location?.state
            ? ""
            : !isModuleReadWriteAccessForContact
            ? user?._id
            : businessWise
            ? ""
            : selectedEmpIdForApi?.length === 0
            ? user?._id
            : selectedEmpIdForApi?._id,
      },
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.getContact,
      data
    );
    if (res?.success) {
      setallContacts(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      if (location?.state) {
        if (selectedEmpIdForApi?._id) {
          handleGetContact();
        }
      } else {
        handleGetContact();
      }
    }
    closeAllModals();
  }, [page, pageLimit, search, selectedEmpIdForApi, businessWise]);

  useEffect(() => {
    if (selectedEmpIdForApi?.length === 0 && location?.state?._id) {
      setSelectedEmpIdForApi(location?.state);
      setselectedEmpId(location?.state);
    }
  }, [location]);

  useEffect(() => {
    if (currentWorkSpaceRole === "owner" && !location?.state?._id) {
      setIsBusinessWise(true);
      setBusinessWise(true);
    }
  }, [currentWorkSpaceRole, location]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ContactsHeader
            handleGetContact={handleGetContact}
            setPage={setPage}
            setSearch={setSearch}
            search={search}
            allContacts={allContacts}
            isModuleReadWriteAccessForContact={
              isModuleReadWriteAccessForContact
            }
            setassigneeModal={setassigneeModal}
          />
          {isBusinessWise && !selectedEmpId?.name && (
            <div className="d-flex justify-content-end">
              <span
                className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                style={{
                  backgroundColor: "#3862DD",
                  color: "#ffff",
                  zIndex: 9999,
                }}
              >
                Business Wise
                <span className="initial-wrap w-30p">
                  <svg
                    onClick={() => {
                      setIsBusinessWise(false);
                      setBusinessWise(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    className="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </span>
              </span>
            </div>
          )}
          {selectedEmpId?.name && !isBusinessWise && (
            <div className="d-flex justify-content-end">
              <span
                className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                style={{
                  backgroundColor: "#3862DD",
                  color: "#ffff",
                  zIndex: 9999,
                }}
              >
                {selectedEmpId?.name}
                <span className="initial-wrap w-30p">
                  <svg
                    onClick={() => {
                      resetSelectedEmployee();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    className="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </span>
              </span>
            </div>
          )}
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allContacts?.length > 0 && (
                <>
                  <ContactTable
                    allContacts={allContacts}
                    pageLimit={pageLimit}
                    page={page}
                    handleGetContact={handleGetContact}
                    seteditContact={seteditContact}
                    editContact={editContact}
                  />
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allContacts?.length}
                    align={"end"}
                  />
                </>
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && allContacts?.length === 0 && (
                <NoData title="No contact added" />
              )}
            </div>
          </div>
        </div>
        <CreateContact
          handleGetContact={handleGetContact}
          setEditContactData={seteditContact}
          editContactData={editContact}
        />
        {assigneeModal && (
          <AssigneeModal
            setassigneeModal={setassigneeModal}
            assigneeModal={assigneeModal}
            selectedEmpId={selectedEmpId}
            setselectedEmpId={setselectedEmpId}
            handleRefresh={handleFilterAssignee}
            isBusinessWise={isBusinessWise}
            setIsBusinessWise={setIsBusinessWise}
            currentWorkSpaceRole={currentWorkSpaceRole}
          />
        )}
      </div>
    </div>
  );
};

export default Contacts;
