import React from "react";

const CallsHeader = ({
  search,
  setSearch,
  setPage,
  handleGetAllCalls,
  relatedTo,
  setrelatedTo,
  setassigneeModal,
  setDateFilter,
  isModuleReadWriteAccessForCalls,
}) => {
  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center">
          <span className="todoapp-title  link-dark">
            <h1 className="header-title-font ">Calls</h1>

            {/* <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                data-bs-toggle="modal"
                data-bs-target="#create_call_modal"
              >
                Create Call
              </button>
            </div> */}
          </span>
        </div>
        <div className="todo-options-wrap">
          <div className="todo-toolbar me-3">
            <select
              className="form-select form-select-md"
              required
              value={relatedTo}
              onChange={(e) => setrelatedTo(e.target.value)}
            >
              <option value="">All</option>
              <option value="Contact">Contact</option>
              <option value="Lead">Lead</option>
              <option value="Deal">Deal</option>
            </select>
          </div>

          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className={`me-2 fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
                &nbsp; Filter
              </span>
              <div
                role="menu"
                className="dropdown-menu"
                style={{ maxHeight: "40vh", overflow: "auto" }}
              >
                {isModuleReadWriteAccessForCalls && (
                  <div className="d-flex justify-content-between">
                    <div
                      className="dropdown-item text-capitalize"
                      onClick={() => {
                        setassigneeModal(true);
                      }}
                    >
                      1. Assignee
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  <div
                    className="dropdown-item text-capitalize"
                    onClick={() => {
                      setDateFilter(true);
                    }}
                  >
                    {!isModuleReadWriteAccessForCalls ? "1" : "2"}. Deadline
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
            onClick={() => {
              handleGetAllCalls();
            }}
          >
            <span className="btn-icon-wrap">
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
          <div className="v-separator d-lg-inline-block d-none"></div>
          <form className="d-sm-block d-none" role="search">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </form>
        </div>
      </header>
    </>
  );
};

export default CallsHeader;
